import site from 'shared/utils/site'

// 保有資格選択肢

const hoikuOfferQualificationChoices = [
    //保育用（見た目だけ）
    { label: '保育士', value: '保育士', yuusensikaku: ['保育士'] },
    { label: '保育教諭', value: '保育教諭', yuusensikaku: ['保育教諭'] },
    {
        label: '児童発達支援管理責任者',
        value: '児童発達支援管理責任者',
        yuusensikaku: ['児童発達支援管理責任者'],
    },
    {
        label: '園長',
        value: '園長',
        yuusensikaku: ['保育士', '幼稚園教諭', '保育教諭'],
    },
    { label: '幼稚園教諭', value: '幼稚園教諭', yuusensikaku: ['幼稚園教諭'] },
    { label: '保育補助', value: '保育補助', yuusensikaku: ['保育補助'] },
    { label: '児童指導員', value: '児童指導員', yuusensikaku: ['児童指導員'] },
    {
        label: '放課後児童支援員/学童指導員',
        value: '放課後児童支援員/学童指導員',
        yuusensikaku: ['放課後児童支援員/学童指導員'],
    },
    { label: 'その他', value: 'その他', yuusensikaku: [] },
]
const kaigoOfferQualificationChoices = [
    // { label: '介護職・ヘルパー', value: '介護職・ヘルパー' },
    { label: '介護福祉士', value: '介護福祉士', yuusensikaku: ['介護福祉士'] },
    {
        label: '実務者研修',
        value: 'ヘルパー１級',
        yuusensikaku: [
            '介護職員実務者研修',
            '介護職員基礎研修',
            'ヘルパー１級',
        ],
    }, //実務者研修 DBの値がヘルパー1級のためこの表記
    {
        label: '初任者研修',
        value: 'ヘルパー２級',
        yuusensikaku: ['介護職員初任者研修'],
    }, //初任者研修  DBの値がヘルパー2級のためこの表記
    {
        label: '資格なし',
        value: '資格なし',
        yuusensikaku: ['資格なし（職種経験有）', '資格なし（職種経験無）'],
    },
    {
        label: '介護支援専門員',
        value: '介護支援専門員',
        yuusensikaku: ['介護支援専門員'],
    },
    {
        label: '主任介護支援専門員',
        value: '主任介護支援専門員',
        yuusensikaku: ['主任介護支援専門員'],
    },
    {
        label: '支援員',
        value: '支援員',
        yuusensikaku: ['資格なし（職種経験有）', '資格なし（職種経験無）'],
    },
    { label: '社会福祉士', value: '社会福祉士', yuusensikaku: ['社会福祉士'] },
    {
        label: '精神保健福祉士（PSW）',
        value: '精神保健福祉士（PSW）',
        yuusensikaku: ['精神保健福祉士'],
    },
    {
        label: '医療ソーシャルワーカー（MSW）',
        value: '医療ソーシャルワーカー（MSW）',
        yuusensikaku: [],
    },
    {
        label: '社会福祉主事',
        value: '社会福祉主事',
        yuusensikaku: ['資格なし（職種経験有）', '資格なし（職種経験無）'],
    },
    { label: '生活相談員', value: '生活相談員', yuusensikaku: [] },
    {
        label: '支援相談員',
        value: '支援相談員',
        yuusensikaku: ['資格なし（職種経験有）', '資格なし（職種経験無）'],
    },
    {
        label: '看護助手',
        value: '看護助手',
        yuusensikaku: [
            '看護助手',
            '資格なし（職種経験有）',
            '資格なし（職種経験無）',
        ],
    },
    {
        label: 'サービス提供責任者',
        value: 'サービス提供責任者',
        yuusensikaku: ['サービス提供責任者'],
    },
    {
        label: 'サービス管理責任者',
        value: 'サービス管理責任者',
        yuusensikaku: ['サービス管理責任者研修'],
    },
    {
        label: '児童発達支援管理責任者',
        value: '児童発達支援管理責任者',
        yuusensikaku: ['児童発達支援管理責任者'],
    },
    {
        label: '児童発達支援管理者',
        value: '児童発達支援管理者',
        yuusensikaku: ['児童発達支援管理責任者'],
    },
    {
        label: '施設長（施設長候補）',
        value: '施設長（施設長候補）',
        yuusensikaku: [
            '介護職員実務者研修',
            '介護職員基礎研修',
            'ヘルパー１級',
            '介護福祉士',
        ],
    },
    { label: '施設長候補', value: '施設長候補', yuusensikaku: [] },
    { label: '柔道整復師', value: '柔道整復師', yuusensikaku: [] },
    {
        label: '福祉用具専門相談員',
        value: '福祉用具専門相談員',
        yuusensikaku: ['資格なし（職種経験有）', '資格なし（職種経験無）'],
    },
    {
        label: '事務',
        value: '事務',
        yuusensikaku: ['資格なし（職種経験有）', '資格なし（職種経験無）'],
    },
    { label: '正看護師', value: '正看護師', yuusensikaku: [] },
    { label: '助産師', value: '助産師', yuusensikaku: [] },
    { label: '保健師', value: '保健師', yuusensikaku: [] },
    { label: '准看護師', value: '准看護師', yuusensikaku: [] },
    {
        label: '医療事務',
        value: '医療事務',
        yuusensikaku: ['資格なし（職種経験有）', '資格なし（職種経験無）'],
    },
    { label: '保育士', value: '保育士', yuusensikaku: [] },
    { label: '児童指導員', value: '児童指導員', yuusensikaku: [] },
    {
        label: '放課後児童支援員/学童指導員',
        value: '放課後児童支援員/学童指導員',
        yuusensikaku: [],
    },
    {
        label: 'その他',
        value: 'その他',
        yuusensikaku: [
            '相談支援専門員',
            '第一種普通自動車免許',
            '認知症介護実践者研修',
        ],
    },
    // { label: '登録ヘルパー（訪問介護）', value: '登録ヘルパー（訪問介護）' },
    // { label: '登録ヘルパー', value: '登録ヘルパー' },
    // { label: '精神保健福祉士', value: '精神保健福祉士' },
]

const kangoOfferQualificationChoices = [
    { label: '正看護師', value: '正看護師', yuusensikaku: ['正看護師'] },
    { label: '准看護師', value: '准看護師', yuusensikaku: ['准看護師'] },
    { label: '助産師', value: '助産師', yuusensikaku: ['助産師'] },
    { label: '保健師', value: '保健師', yuusensikaku: ['保健師'] },
    {
        label: '看護助手',
        value: '看護助手',
        yuusensikaku: [
            '看護助手',
            '資格なし（職種経験有）',
            '資格なし（職種経験無）',
        ],
    },
    { label: '臨床検査技師', value: '臨床検査技師', yuusensikaku: [] },
    { label: '臨床工学技士', value: '臨床工学技士', yuusensikaku: [] },
    { label: '診療放射線技師', value: '診療放射線技師', yuusensikaku: [] },
    {
        label: '医療ソーシャルワーカー（MSW）',
        value: '医療ソーシャルワーカー（MSW）',
        yuusensikaku: [],
    },
    { label: '薬剤師', value: '薬剤師', yuusensikaku: [] },
    { label: '医師', value: '医師', yuusensikaku: [] },
    {
        label: '公認心理士/臨床心理士',
        value: '公認心理士/臨床心理士',
        yuusensikaku: [],
    },
    { label: '看護教員', value: '看護教員', yuusensikaku: [] },
    {
        label: '事務',
        value: '事務',
        yuusensikaku: ['資格なし（職種経験有）', '資格なし（職種経験無）'],
    },
    { label: 'その他', value: 'その他', yuusensikaku: [] },
]

const posOfferQualificationChoices = [
    { label: '理学療法士', value: '理学療法士', yuusensikaku: ['理学療法士'] },
    { label: '言語聴覚士', value: '言語聴覚士', yuusensikaku: ['言語聴覚士'] },
    { label: '作業療法士', value: '作業療法士', yuusensikaku: ['作業療法士'] },
    { label: '鍼灸師', value: '鍼灸師', yuusensikaku: ['鍼灸師'] },
    {
        label: 'あん摩マッサージ指圧師',
        value: 'あん摩マッサージ指圧師',
        yuusensikaku: ['あん摩マッサージ指圧師'],
    },
    { label: '視能訓練士', value: '視能訓練士', yuusensikaku: ['視能訓練士'] },
    {
        label: '整体師/セラピスト',
        value: '整体師/セラピスト',
        yuusensikaku: [],
    },
    { label: '柔道整復師', value: '柔道整復師', yuusensikaku: ['柔道整復師'] },
    {
        label: '診療放射線技師',
        value: '診療放射線技師',
        yuusensikaku: ['診療放射線技師'],
    },
    {
        label: '臨床検査技師',
        value: '臨床検査技師',
        yuusensikaku: ['臨床検査技師'],
    },
    {
        label: '臨床工学技士',
        value: '臨床工学技士',
        yuusensikaku: ['臨床工学技士'],
    },
    { label: 'その他', value: 'その他', yuusensikaku: [] },
]
const eiyoushiOfferQualificationChoices = [
    { label: '栄養士', value: '栄養士', yuusensikaku: ['栄養士'] },
    { label: '管理栄養士', value: '管理栄養士', yuusensikaku: ['管理栄養士'] },
    { label: '調理師', value: '調理師', yuusensikaku: ['調理師'] },
    {
        label: '調理スタッフ（調理補助）',
        value: '調理スタッフ（調理補助）',
        yuusensikaku: [],
    },
    { label: '無資格（MM）', value: '無資格（MM）', yuusensikaku: [] },
]

const kaigoRecordQualificationChoices = [
    // { label: '介護職・ヘルパー', value: '介護職・ヘルパー' },
    { label: '介護福祉士', value: '介護福祉士' },
    { label: '実務者研修', value: 'ヘルパー1級' }, //実務者研修 DBの値がヘルパー1級のためこの表記 ※offerは全角数字だがrecordは半角数字になっているので注意
    { label: '初任者研修', value: 'ヘルパー2級' }, //初任者研修  DBの値がヘルパー2級のためこの表記 ※offerは全角数字だがrecordは半角数字になっているので注意
    { label: '資格なし', value: '資格なし' },
    { label: '介護支援専門員', value: '介護支援専門員' },
    { label: '主任介護支援専門員', value: '主任介護支援専門員' },
    { label: '支援員', value: '支援員' },
    { label: '社会福祉士', value: '社会福祉士' },
    { label: '精神保健福祉士（PSW）', value: '精神保健福祉士（PSW）' },
    {
        label: '医療ソーシャルワーカー（MSW）',
        value: '医療ソーシャルワーカー（MSW）',
    },
    { label: '社会福祉主事', value: '社会福祉主事' },
    { label: '生活相談員', value: '生活相談員' },
    { label: '支援相談員', value: '支援相談員' },
    { label: '看護助手', value: '看護助手' },
    { label: 'サービス提供責任者', value: 'サービス提供責任者' },
    { label: 'サービス管理責任者', value: 'サービス管理責任者' },
    { label: '児童発達支援管理責任者', value: '児童発達支援管理責任者' },
    { label: '児童発達支援管理者', value: '児童発達支援管理者' },
    { label: '施設長（施設長候補）', value: '施設長（施設長候補）' },
    { label: '施設長候補', value: '施設長候補' },
    { label: '柔道整復師', value: '柔道整復師' },
    { label: '福祉用具専門相談員', value: '福祉用具専門相談員' },
    { label: '事務', value: '事務' },
    { label: '正看護師', value: '正看護師' },
    { label: '助産師', value: '助産師' },
    { label: '保健師', value: '保健師' },
    { label: '准看護師', value: '准看護師' },
    { label: '医療事務', value: '医療事務' },
    { label: '保育士', value: '保育士' },
    { label: '児童指導員', value: '児童指導員' },
    {
        label: '放課後児童支援員/学童指導員',
        value: '放課後児童支援員/学童指導員',
    },
    { label: 'その他', value: 'その他' },
    // { label: '登録ヘルパー（訪問介護）', value: '登録ヘルパー（訪問介護）' },
    // { label: '登録ヘルパー', value: '登録ヘルパー' },
    // { label: '精神保健福祉士', value: '精神保健福祉士' },
]
const hoikuRecordQualificationChoices = [
    //保育用
    { label: '保育士', value: '保育士' },
    { label: '保育教諭', value: '保育教諭' },
    { label: '児童発達支援管理責任者', value: '児童発達支援管理責任者' },
    { label: '園長', value: '園長' },
    { label: '幼稚園教諭', value: '幼稚園教諭' },
    { label: '保育補助', value: '保育補助' },
    { label: '児童指導員', value: '児童指導員' },
    {
        label: '放課後児童支援員/学童指導員',
        value: '放課後児童支援員/学童指導員',
    },
    { label: 'その他', value: 'その他' },
]

const kangoRecordQualificationChoices = [
    { label: '正看護師', value: '正看護師' },
    { label: '准看護師', value: '准看護師' },
    { label: '助産師', value: '助産師' },
    { label: '保健師', value: '保健師' },
    { label: '看護助手', value: '看護助手' },
    { label: '臨床検査技師', value: '臨床検査技師' },
    { label: '臨床工学技士', value: '臨床工学技士' },
    { label: '診療放射線技師', value: '診療放射線技師' },
    {
        label: '医療ソーシャルワーカー（MSW）',
        value: '医療ソーシャルワーカー（MSW）',
    },
    { label: '薬剤師', value: '薬剤師' },
    { label: '医師', value: '医師' },
    { label: '事務', value: '事務' },
    { label: 'その他', value: 'その他' },
]

const posRecordQualificationChoices = [
    { label: '理学療法士', value: '理学療法士' },
    { label: '言語聴覚士', value: '言語聴覚士' },
    { label: '作業療法士', value: '作業療法士' },
    { label: '鍼灸師', value: '鍼灸師' },
    { label: 'あん摩マッサージ指圧師', value: 'あん摩マッサージ指圧師' },
    { label: '視能訓練士', value: '視能訓練士' },
    { label: '整体師/セラピスト', value: '整体師/セラピスト' },
    { label: '柔道整復師', value: '柔道整復師' },
    { label: '診療放射線技師', value: '診療放射線技師' },
    { label: '臨床検査技師', value: '臨床検査技師' },
    { label: '臨床工学技士', value: '臨床工学技士' },
    { label: 'その他', value: 'その他' },
]
const eiyoushiRecordQualificationChoices = [
    { label: '栄養士', value: '栄養士' },
    { label: '管理栄養士', value: '管理栄養士' },
    { label: '調理師', value: '調理師' },
    { label: '調理スタッフ（調理補助）', value: '調理スタッフ（調理補助）' },
    { label: '無資格（MM）', value: '無資格（MM）' },
]

export const offerQualificationChoices = site.isHoiku()
    ? hoikuOfferQualificationChoices
    : site.isKango()
    ? kangoOfferQualificationChoices
    : site.isKaigo()
    ? kaigoOfferQualificationChoices
    : site.isPos()
    ? posOfferQualificationChoices
    : site.isEiyoushi()
    ? eiyoushiOfferQualificationChoices
    : null

export const recordQualificationChoices = site.isHoiku()
    ? hoikuRecordQualificationChoices
    : site.isKango()
    ? kangoRecordQualificationChoices
    : site.isKaigo()
    ? kaigoRecordQualificationChoices
    : site.isPos()
    ? posRecordQualificationChoices
    : site.isEiyoushi()
    ? eiyoushiRecordQualificationChoices
    : null
