import {
    Checkbox,
    Text,
    Box,
    Heading,
    HStack,
    Stack,
    Spacer,
} from '@chakra-ui/react'
import { useDisclosure } from '@chakra-ui/react'
import { parseISO } from 'date-fns'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'

import {
    useDeleteAnnouncementMutation,
    useDeleteMaintenanceMutation,
    useListNotificationsQuery,
} from '../api'
import { NotificationModal } from './NotificationModal'
import { NotificationTable, AdminNotificationTable } from './NotificationTable'

import { Pagination } from '@/components/Elements'

const transformListNotificationsResponse = (response) => {
    // TODO: replace to joi
    const parseDate = (notification) => {
        return {
            ...notification,
            period: {
                ...notification.period,
                begin: parseISO(notification.period.begin),
                end: notification.period.end
                    ? parseISO(notification.period.end)
                    : undefined,
            },
            duration: notification.duration
                ? {
                      ...notification.duration,
                      begin: parseISO(notification.duration.begin),
                      end: notification.duration.end
                          ? parseISO(notification.duration.end)
                          : undefined,
                  }
                : undefined,
        }
    }
    return { ...response, items: response.items.map((item) => parseDate(item)) }
}

const useListNotifications = (...args) => {
    const { data, ...result } = useListNotificationsQuery(...args)
    if (data) {
        return {
            data: transformListNotificationsResponse(data),
            ...result,
        }
    }
    return { data, ...result }
}

const useNotificationListData = () => {
    const [page, setPage] = useState(1)
    const { data, isSuccess } = useListNotifications({ page })
    return { page, setPage, data, isSuccess }
}

/**
 * @type {React.FC<{
 *     renderHeaderButton?: () => JSX.Element
 * }>}
 */
export const NotificationListContainer = ({ renderHeaderButton }) => {
    const { setPage, data, isSuccess } = useNotificationListData()

    if (!isSuccess) {
        // TODO: ぐるぐる等
        return <div>Loading...</div>
    }

    return (
        <NotificationList
            notifications={data.items}
            renderHeaderButton={renderHeaderButton}
            renderPagination={() => (
                <Pagination
                    totalCount={data.paging.pageCount}
                    currentPage={data.paging.page}
                    handlePageChanged={setPage}
                />
            )}
        />
    )
}

NotificationListContainer.propTypes = {
    renderHeaderButton: PropTypes.func,
}

// 新TOP画面表示用おしらせ
export const TopNotificationListContainer = () => {
    const { data, isSuccess } = useNotificationListData()

    if (!isSuccess) {
        // TODO: ぐるぐる等
        return <div>Loading...</div>
    }

    return <TopNotificationList notifications={data.items} />
}

/**
 * @type {React.FC<{
 *     notifications: any[]
 *     renderPagination?: () => JSX.Element
 *     renderHeaderButton?: () => JSX.Element
 * }>}
 */
export const NotificationList = ({
    notifications,
    renderPagination,
    renderHeaderButton,
}) => {
    return (
        <Box>
            <Stack
                bg="primary.50"
                maxWidth="1000px"
                w="100%"
                p="35px 25px"
                margin="25px auto 20px auto"
            >
                <Heading
                    as="h5"
                    fontSize="20px"
                    color="#3CB37A"
                    fontWeight="bolder"
                >
                    お知らせ
                </Heading>
                <NotificationTable
                    notifications={notifications}
                    noTopNotification
                />
                <Box minW="200px" pt="5px">
                    {renderHeaderButton && renderHeaderButton()}
                </Box>
            </Stack>
            {renderPagination && renderPagination()}
        </Box>
    )
}

NotificationList.propTypes = {
    notifications: PropTypes.array.isRequired,
    renderPagination: PropTypes.func,
    renderHeaderButton: PropTypes.func,
}

export const TopNotificationList = ({ notifications }) => {
    return (
        <Box>
            <HStack
                bg="primary.50"
                w="100%"
                p="10px 15px"
                margin="25px auto 20px auto"
                alignItems="flex-start"
            >
                <Heading width="110px" pt="3px">
                    お知らせ
                </Heading>
                <NotificationTable notifications={notifications} />
            </HStack>
        </Box>
    )
}

TopNotificationList.propTypes = {
    notifications: PropTypes.array.isRequired,
}

/**
 * @returns {{
 *     paginate: {
 *         page: number
 *         onMove: (page: number) => void | Promise<void>
 *     }
 *     past: {
 *         isShow: boolean
 *         onToggle: () => void | Promise<void>
 *     }
 *     fetch: {
 *         isLoading: boolean
 *         isSuccess: boolean
 *         refetch: () => void | Promise<void>
 *     }
 *     modal: {
 *         onOpen: () => void | Promise<void>
 *         onClose: () => void | Promise<void>
 *         onToggle: () => void | Promise<void>
 *         render: () => JSX.Element
 *     }
 *     data: { items: any[]; paging: any }
 * }}
 */
const useAdminNotificationListData = () => {
    const [page, setPage] = useState(1)
    const [showPast, setShowPast] = useState(false)
    const toggleShowPast = useCallback(() => {
        setPage(1)
        setShowPast((prev) => !prev)
    }, [])
    const { data, isLoading, isSuccess, refetch } = useListNotifications({
        page,
        period: showPast ? 'all' : 'future',
        showHidden: true,
    })
    const [deleteAnnouncementTrigger] = useDeleteAnnouncementMutation()
    const [deleteMaintenanceTrigger] = useDeleteMaintenanceMutation()
    /** @type {[number | undefined, (index: number | undefined) => void]} */
    const [editIndex, setEditIndex] = useState()
    const { isOpen, onOpen, onClose, onToggle } = useDisclosure()
    const onCloseModal = useCallback(() => {
        onClose()
        setEditIndex(undefined)
    }, [])

    const dataWithOperations = useMemo(() => {
        if (data === null || data === undefined) {
            return data
        }
        return {
            ...data,
            items: data.items.map((item, index) => ({
                ...item,
                operations: {
                    delete: () => {
                        if (!window.confirm('本当に削除しますか？')) {
                            return
                        }
                        ;(item.__t === 'Announcement'
                            ? deleteAnnouncementTrigger
                            : deleteMaintenanceTrigger)(item._id)
                            .unwrap()
                            .then(() => {
                                if (data.items.length <= 1) {
                                    setPage(Math.max(page - 1, 1))
                                }
                                alert('削除しました。')
                            })
                    },
                    edit: () => {
                        setEditIndex(index)
                        onOpen()
                    },
                },
            })),
        }
    }, [data])

    const renderModal = useCallback(
        () => (
            <NotificationModal
                isOpen={isOpen}
                onClose={onCloseModal}
                notification={
                    editIndex === undefined ? undefined : data.items[editIndex]
                }
            />
        ),
        [data, editIndex, isOpen, onCloseModal]
    )

    return {
        paginate: {
            page,
            onMove: setPage,
        },
        past: {
            isShow: showPast,
            onToggle: toggleShowPast,
        },
        fetch: {
            isLoading,
            isSuccess,
            refetch,
        },
        modal: {
            onOpen: onOpen,
            onClose: onCloseModal,
            onToggle: onToggle,
            render: renderModal,
        },
        data: dataWithOperations,
    }
}

/** @type {React.FC} */
export const AdminNotificationListContainer = () => {
    const {
        paginate: { onMove },
        past: { isShow: isShowPast, onToggle: toggleShowPast },
        modal: { onOpen, render: renderModal },
        fetch: { isSuccess },
        data,
    } = useAdminNotificationListData()

    if (!isSuccess) {
        // TODO: ぐるぐる等
        return <div>Loading...</div>
    }

    return (
        <AdminNotificationList
            notifications={data.items}
            renderPagination={() => (
                <Pagination
                    totalCount={data.paging.pageCount}
                    currentPage={data.paging.page}
                    handlePageChanged={onMove}
                />
            )}
            renderHeaderButton={() => (
                <>
                    <div
                        style={{
                            marginLeft: 'auto',
                            marginTop: '0',
                        }}
                    >
                        <Checkbox
                            isChecked={isShowPast}
                            onChange={toggleShowPast}
                        >
                            <Text>過去のお知らせを表示する</Text>
                        </Checkbox>
                        <a
                            onClick={onOpen}
                            style={{
                                color: '#3CB37A',
                                fontWeight: 'bolder',
                                cursor: 'pointer',
                                marginLeft: '1rem',
                            }}
                        >
                            新規登録
                        </a>
                    </div>
                    {renderModal()}
                </>
            )}
        />
    )
}

/**
 * @type {React.FC<{
 *     notifications: any[]
 *     renderHeaderButton?: () => JSX.Element
 *     renderPagination?: () => JSX.Element
 * }>}
 */
export const AdminNotificationList = ({
    notifications,
    renderHeaderButton,
    renderPagination,
}) => {
    return (
        <Box>
            <Stack
                bg="primary.50"
                maxWidth="1000px"
                w="100%"
                p="35px 25px"
                margin="25px auto 20px auto"
            >
                <HStack>
                    <Heading
                        as="h5"
                        fontSize="20px"
                        color="#3CB37A"
                        fontWeight="bolder"
                    >
                        お知らせ
                    </Heading>
                    {renderHeaderButton && renderHeaderButton()}
                </HStack>

                <AdminNotificationTable notifications={notifications} />
            </Stack>
            {renderPagination && renderPagination()}
        </Box>
    )
}

AdminNotificationList.propTypes = {
    notifications: PropTypes.array.isRequired,
    renderHeaderButton: PropTypes.func,
    renderPagination: PropTypes.func,
}
