import site from 'shared/utils/site'

import { hoikuQualificationChoices } from './jobOfferFilterChoices.js'

// 勤務形態選択肢

//介護・保育
const kaigoWorkShiftChoices = [
    { label: 'フル常勤', value: 'フル常勤' },
    { label: '日勤常勤', value: '日勤常勤' },
    { label: '夜勤常勤', value: '夜勤常勤' },
    { label: '日勤パート', value: '日勤パート' },
    { label: '夜勤バイト', value: '夜勤パート' },
    { label: 'その他', value: 'その他' },
]

//看護・POS
const kangoWorkShiftChoices = [
    { label: 'フル常勤', value: 'フル常勤' },
    { label: '日勤常勤', value: '日勤常勤' },
    { label: '夜勤常勤', value: '夜勤常勤' },
    { label: '日勤パート', value: '日勤パート' },
    { label: 'フルタイムパート', value: 'フルタイムパート' },
    { label: '夜勤バイト', value: '夜勤パート' },
    { label: 'その他', value: 'その他' },
]

//栄養士
const eiyoushiWorkShiftChoices = [
    { label: '正社員', value: '正社員' },
    { label: 'フルタイムパート', value: 'フルタイムパート' },
    { label: '日勤パート', value: '日勤パート' },
]

export const workShiftChoices = site.isKaigo()
    ? kaigoWorkShiftChoices
    : site.isHoiku()
    ? kaigoWorkShiftChoices
    : site.isKango()
    ? kangoWorkShiftChoices
    : site.isPos()
    ? kangoWorkShiftChoices
    : site.isEiyoushi() 
    ? eiyoushiWorkShiftChoices
    : null
