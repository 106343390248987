import site from 'shared/utils/site'

//求人特徴-勤務形態マッピング

//全環境
export const workShiftMappings = {
    フル常勤: ['フルタイム'],
    日勤常勤: ['フルタイム'],
    夜勤常勤: ['フルタイム'],
    日勤パート: ['パート'],
    夜勤パート: ['パート'],
}

//成約実績-勤務形態マッピング

//看護・POS
const kangoRecodeWorkShiftMappings = {
    夜勤パート: ['夜勤バイト'],
    フルタイムパート: ['フルパート'],
}

//介護・保育
const notkangoRecodeWorkShiftMappings = {
    夜勤パート: ['夜勤バイト'],
}

export const recodeWorkShiftMappings = site.isKaigo()
    ? notkangoRecodeWorkShiftMappings
    : site.isHoiku()
    ? notkangoRecodeWorkShiftMappings
    : site.isKango()
    ? kangoRecodeWorkShiftMappings
    : site.isPos()
    ? kangoRecodeWorkShiftMappings
    : site.isEiyoushi()
    ? kangoRecodeWorkShiftMappings
    : null
